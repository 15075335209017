import React from 'react';
import { Block } from 'src/typings/components/block';

export type FooterProps = {
  elements?: Block[];
  updateFooterHeight: (height: number) => void;
  renderBlock: (block: Block) => JSX.Element;
};

class Footer extends React.PureComponent<FooterProps> {
  componentDidMount() {
    const footer = document ? document.getElementsByTagName('footer')[0] : null;
    const footerHeight = footer ? footer.offsetHeight : 0;
    this.props.updateFooterHeight(footerHeight);
  }

  render() {
    const { elements, renderBlock } = this.props;

    return (
      <footer className="footer" role="contentinfo">
        {elements && elements.map(renderBlock)}
      </footer>
    );
  }
}

export default Footer;
