import React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../../typings/state/state';

type Props = {
  level?: number;
  className?: string;
  children: any;
  ariaLabelledBy: string;
};

const mapStateToProps = (state: State) =>  ({
  ariaLabelledBy:state?.page.articleModal.ariaLabelledBy
});

/**
 * This function give the aria-labelledby value to open modal title id attribut.
 * see doc on aria-labelledby for modal.
 *
 * @param  {string}  className           the className set on the h1-h5 html tag.
 * @param  {string}  ariaLabelledBy      aria-laballedby default value.
 * @returns {string | undefined}         return a string of undefined value.
 */
const addIdAttributForModalTitle = (className: string | undefined, ariaLabelledBy: string): string | undefined => {
  if ( className && className === 'title article__fragment article__title' ) return ariaLabelledBy;
  else return undefined;
}


const Heading= ({ ariaLabelledBy, className, level, children }: Props) => {
    
    switch (level) {
      case 1:
        return <h1 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h1>;
      case 2:
        return <h2 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h2>;
      case 3:
        return <h3 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h3>;
      case 4:
        return <h4 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h4>;
      case 5:
        return <h5 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h5>;
      case 6:
        return <h6 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h6>;
      default:
        return <h1 id={addIdAttributForModalTitle(className, ariaLabelledBy)} className={className}>{children}</h1>;
    }
}

export default connect(mapStateToProps)(Heading);
