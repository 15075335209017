import { AdvancedFooterRightColumnDirection } from 'src/typings/constants/templateFooterConst';

/**
 * Slice with equality the links for the right of the footer column in two parts
 * and return the links showed inside the Right Column footer in the left or right emplacement.
 * In a particular case the const LeftColumMinItemNumber is the number of the minimum links required
 * in the left emplacement insteed of splice the column links in equal number.
 *
 * @param {string} direction The emplacement of the links : (right or reft).
 * @param {Array} columnLinks Array of all the links inside the right column.
 *
 * @returns {Array} Return an array of links for the footer.
 */
export const sliceRightColumnLinks = (columnLinks, direction: AdvancedFooterRightColumnDirection) => {
  let sliceLeftNumber = 0;
  let sliceRightNumber = 0;
  const LeftColumMinItemNumber = 3;

  switch (direction) {
    case AdvancedFooterRightColumnDirection.RIGHT:
      if (columnLinks.length <= LeftColumMinItemNumber) {
        return [];
      } else if (columnLinks.length < LeftColumMinItemNumber * 2) {
        sliceRightNumber = Math.max(columnLinks.length - LeftColumMinItemNumber, 0);
        sliceLeftNumber = columnLinks.length - sliceRightNumber;
      } else {
        sliceRightNumber = Math.floor(columnLinks.length / 2);
      }

      return columnLinks.slice(-sliceRightNumber);

    case AdvancedFooterRightColumnDirection.LEFT:
      if (columnLinks.length < LeftColumMinItemNumber * 2) {
        sliceRightNumber = Math.max(columnLinks.length - LeftColumMinItemNumber, 0);
        sliceLeftNumber = columnLinks.length - sliceRightNumber;
      } else {
        sliceRightNumber = Math.floor(columnLinks.length / 2);
        sliceLeftNumber = columnLinks.length % 2 === 0 ? sliceRightNumber : sliceRightNumber + 1;
      }

      return columnLinks.slice(0, sliceLeftNumber);
    default:
      return [];
  }
};
