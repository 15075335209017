import React, { FunctionComponent } from 'react';
import '@canalplus/mycanal-headerfooter/dist/footer.min.css';
import { Link } from 'react-router-dom';
import MycanalFooter from '@canalplus/mycanal-headerfooter/footer';

import config from './config.json';
import { Block } from 'src/typings/components/block';
import { getLinkHref } from 'src/shared/helpers/uriHelper';
import { LinkFooter } from 'src/typings/components/link';

type FooterV5Props = { columnList: LinkFooter[][]; renderBlock: (block: Block) => JSX.Element; trackingBlocks?: Block[] };

const COLUMN_LEFT = 'Site';
const COLUMN_RIGHT = 'Services';

/* FooterV5 was build to be used in Jobs mini site
 * In order to be more usable by other mini sites it would require
 * refactoring allowing more granularity about columns naming
 * and links repartition
 */
const FooterV5: FunctionComponent<FooterV5Props> = ({ columnList = [], renderBlock, trackingBlocks = [] }) => {
  const [siteLinks, legalLinks, canalGroupLinks] = columnList;

  const setRow = ({ Component, props: cProps, text }, index) => (
    <Component {...cProps} key={index} label={text}>
      {text}
    </Component>
  );

  const createLinks = (column: LinkFooter[] = []) =>
    column
      .map((nav) => {
        if (nav?.link?.[0]) {
          return nav.link[0].rel === 'self'
            ? {
                Component: Link,
                props: { to: getLinkHref(nav.link[0]) },
                text: nav.label,
              }
            : {
                Component: 'a',
                props: { href: getLinkHref(nav.link[0]), target: '_blank' },
                text: nav.label,
              };
        }

        return {
          Component: Link,
          props: { to: '#' },
          text: nav.label,
        };
      })
      .map(setRow);

  const tree = [
    {
      title: COLUMN_LEFT,
      links: [createLinks(siteLinks)],
    },
    {
      title: COLUMN_RIGHT,
      links: [createLinks(legalLinks), createLinks(canalGroupLinks)],
    },
  ];

  return (
    <div className="footer">
      <MycanalFooter dark mainColumnContent={tree} isFeatSocialNetworksFooter socialLinks={config.footer.social} />
      {trackingBlocks && trackingBlocks.map(renderBlock)}
    </div>
  );
};
export default FooterV5;
