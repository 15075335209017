import React, { useState } from 'react';
import { TranscriptionModal } from '../TranscriptionModal'

type Props = {
  autoPlay: boolean;
  muted: boolean;
  loop: boolean;
  controls: boolean;
  playsInline: boolean;
  content: {
    url: string;
    transcriptionTitle: string | undefined;
    transcriptionText: string | undefined;
  };
};

export default function VGIVideoGif({ content, autoPlay, muted, loop, controls, playsInline }: Props) {
  const [showModalContent, setShowModalContent] = useState(false);
  const [focused, setFocused] = useState(false);

  const openTranscriptionModal = () => {
    setShowModalContent(true);
  }

  const closeTranscriptionModal = () => {
    setShowModalContent(false);
  }

  const onVgiGifFocus = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      onTranscriptionFocus();
    }
  }

  const onTranscriptionFocus = () => {
    setFocused(true);
  }

  const onTranscriptionBlur = () => {
    setFocused(false);
  }

  return (
    <>
      <div>
        <video
          className="vgi__gifVideo"
          src={content.url}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          controls={controls}
          playsInline={playsInline}
          tabIndex={0}
          onKeyDown={onVgiGifFocus}
        />

        {focused && content.transcriptionTitle && (
          <div className="text__transcription text__transcription--gif"
            onKeyPress={openTranscriptionModal}
            tabIndex={0}
            onFocus={onTranscriptionFocus}
            onBlur={onTranscriptionBlur}
          >
            <button type="button" aria-expanded="false" className="text__transcription--videoButton"><i
              className="icon ai-arrow-right"></i><span>{content.transcriptionTitle}</span></button>
          </div>
        )}
      </div>

      {showModalContent &&
        <TranscriptionModal
          label={content.transcriptionTitle}
          transcription={content.transcriptionText}
          closeTranscriptionModal={closeTranscriptionModal}
          isTranscriptionModalOpen={showModalContent}
        />
      }
    </>
  )
}
